<template lang="">
  <div  v-loading="loading" element-loading-text="上传中，请勿重复提交"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <input class="" ref="uploadFile" style="visibility:hidden" type='file' @change="handleExceed" />
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="导入服务" name="1">
        <p class="tips-p">
          1.标题栏不可删除或者更改顺序或改变文件中行位置，以免导致格式错误，文件导入失败
        </p>
        <p class="tips-p">2.红色标题为必填字段，缺失必填字段的数据行无法导入</p>

        <el-button type="primary" @click="handleUpload">
          上传文件
        </el-button>
        <p style="margin-top: 10px;font-size: 16px;color: #333">{{fileName}}</p>
        <div class="btn-box">
          <el-button type="primary" @click="hanldeExport(1)"
            >下载模板</el-button
          >
          <el-button type="primary" @click="handleImport(1)"
            >导入模板</el-button
          >
        </div>
      </el-tab-pane>
      <el-tab-pane label="导入商品" name="2">
        <p class="tips-p">
          1.标题栏不可删除或者更改顺序或改变文件中行位置，以免导致格式错误，文件导入失败
        </p>
        <p class="tips-p">2.红色标题为必填字段，缺失必填字段的数据行无法导入</p>

        <el-button type="primary" @click="handleUpload">
          上传文件
        </el-button>
        <p style="margin-top: 10px;font-size: 16px;color: #333">{{fileName}}</p>
        <div class="btn-box">
          <el-button type="primary" @click="hanldeExport(2)"
            >下载模板</el-button
          >
          <el-button type="primary" @click="handleImport(2)"
            >导入模板</el-button
          >
        </div>
      </el-tab-pane>
      <el-tab-pane label="导入会员" name="3">
        <p class="tips-p">
          1.标题栏不可删除或者更改顺序或改变文件中行位置，以免导致格式错误，文件导入失败
        </p>
        <p class="tips-p">2.红色标题为必填字段，缺失必填字段的数据行无法导入</p>

        <el-button type="primary" @click="handleUpload">
          上传文件
        </el-button>
        <p style="margin-top: 10px;font-size: 16px;color: #333">{{fileName}}</p>
        <div class="btn-box">
          <el-button type="primary" @click="hanldeExport(3)"
            >下载模板</el-button
          >
          <el-button type="primary" @click="handleImport(3)"
            >导入模板</el-button
          >
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import axios from "axios";
import { baseUrl } from "../../env.js";
export default {
  data() {
    return {
      activeName: "1",
      exportServiceTemplate: "excel/serviceTemplate/export",
      exportProductTemplate: "excel/productTemplate/export",
      exportMemberTemplate: "excel/memberTemplate/export",
      importServiceTemplate: "excel/serviceTemplate/import",
      importProductTemplate: "excel/productTemplate/import",
      importMemberTemplate: "excel/customerTemplate/import",
      uploadFile: "",
      storeId:"",
      fileName:'',
      loading:false
    };
  },
  created() {
    axios.defaults.baseURL = baseUrl;
    console.log(baseUrl,'baseUrl')
    console.log(this.getLocationParams("storeId"))
  },
  methods: {
    handleUpload() {
      this.$refs.uploadFile.click()
    }, 
    getLocationParams(keyWords) {
       // 提取路由值（字符串）
       let href = window.location.href;
       console.log(href, 'href')
        // 从占位符开始截取路由（不包括占位符）
        let query = href.substring(href.indexOf("?") + 1);
        // 根据 & 切割字符串
        let vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
          let pair = vars[i].split("=");
          // 根据指定的参数名去筛选参数值
          if (pair[0] == keyWords) {
            return pair[1];
          }
        }
        return false;
    },
    downloadExcel(filename, file) {
      const blob = new Blob([file]);
      const fileName = filename || `Excel_${new Date().getTime()}.xls`;
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10以上下载
        navigator.msSaveBlob(blob, fileName);
      }
    },
    handleExceed(response) {
      console.log(response)

      this.uploadFile = response.target.files[0];
      this.fileName = this.uploadFile.name
    },
    hanldeExport(type) {
      // var url;
      // switch (type) {
      //   case 1:
      //     url = this.exportServiceTemplate;
      //     break;
      //   case 2:
      //     url = this.exportProductTemplate;
      //     break;
      //   case 3:
      //     url = this.exportMemberTemplate;
      //     break;
      // }
      // this.getTemplate(url);
      switch (type) {
        case 1:
        window.open('https://img.maochen.vip/service_template.xlsx')
          break;
        case 2:
        window.open('https://img.maochen.vip/service_template.xlsx')
          break;
        case 3:
        window.open('https://img.maochen.vip/customer_template.xlsx')
          break;
      }
     
    },
    handleImport(type) {
        var url;
      switch (type) {
        case 1:
          url = this.importServiceTemplate;
          break;
        case 2:
          url = this.importServiceTemplate;
          break;
        case 3:
          url = this.importMemberTemplate;
          break;
      }
      this.importTemplate(url);
    },
    handleClick(tab, event) {
      this.uploadFile = "";
      this.fileName = ''
      console.log(tab, event);
    },
    getTemplate(url) {
      const vm = this;
      axios
        .get(url)
        .then(function (response) {
          if (response.status == 200) {
            const fileName = `service_${new Date().getTime()}.xls`;
            vm.downloadExcel(fileName, response.data);
          }
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    importTemplate(url) {
      console.log(url, 'url')
      const vm = this;
      if (!vm.uploadFile) {
        this.$message.error('请上传文件')
        return
      }
      const formData = new FormData();
      formData.append('file', vm.uploadFile);
      if(vm.getLocationParams("storeId")) {
        formData.append('storeId', +vm.getLocationParams("storeId"))
      }
      formData.append('storeId', 70)
      if (vm.loading) return
      vm.loading = true
      setTimeout(() => {
        vm.loading = false
      }, 5000000);
      axios
        .post(url,formData,{
          timeout:5000000
        })
        .then(function (response) {
          if (response.status == 200) {
            if (response.data.code ==0) {
              vm.$message.success('导入成功')
            } else {
              vm.$message.error(response.data.msg)
            }
          }
        })
        .finally(() => {
          vm.loading = false
        })
        .catch(function (error) {
          vm.$message.error(error.data.msg)

        });
    },
  },
};
</script>
<style lang="css">
.tips-p {
  text-align: left;
}

.btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.upload-box {
  padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
    color: #FFF;
    background-color: #409EFF;
}
</style>
